import dynamic from 'next/dynamic'
import { useEffect } from 'react'

import { useStore } from '@context/store'

const App = dynamic(() => import('@components/common/App'), {
  ssr: false,
})

const Layout = dynamic(() => import('@components/common/Layout'), {
  ssr: false,
})

const Home = () => {
  const { currentUserId } = useStore()

  useEffect(() => {
    // Trigger refresh in case of log out
  }, [currentUserId])

  if (!currentUserId) {
    // Might need a second render for currentUserId to be set
    return <></>
  }

  return <App />
}

Home.Layout = Layout

export default Home
